import React from "react";
import { v4 as uuid } from "uuid";
import { withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import FormRenderer from "./formRender";
import MutextField from "@material-ui/core/TextField";
import axios from "axios";
import Select2 from "react-select";
import RichTextEditorComponent from "../../components/Reseller/RichTextEditorComponent";

const useStyles = (theme) => ({
  formContainer: {
    marginBottom: theme.spacing(2),
    //padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    width: "100%",
    margin: "auto",
    //marginTop: "1rem",
  },
  questionContainer: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #dbdfea",
    paddingBottom: "1rem",
  },
  questionTop: {
    display: "flex",
  },
  optionContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  addButton: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  deleteButton: {
    marginLeft: theme.spacing(2),
  },
});

class FormBuilderClass extends React.Component {
  constructor(props) {
    super(props);
    let form_content = this.props.form_content;
    let form_title =
      this.props.form_title && this.props.form_title !== ""
        ? this.props.form_title
        : "";
    if (!form_content || form_content === "" || form_content.length <= 0) {
      form_content = [
        {
          title: "",
          id: uuid(),
          questions: [],
          /*questions: [
            {
              id: uuid(),
              questionText: "",
              answerType: "",
              required: false,
              options: [],
              imageUrl: "",
            },
          ],*/
          errorMessage: "",
        },
      ];
    }
    let groups = [];
    if (this.props.groups) {
      groups = this.props.groups.sort(
        (a, b) => a.sequenceNumber - b.sequenceNumber
      );
    }
    //Add group id if missing
    groups = groups.map((group) => {
      if (!group.id || group.id === "") {
        return { ...group, id: uuid() };
      }
      return group;
    });

    this.state = {
      form_title: form_title,
      forms: form_content,
      showSelectList: false,
      //groups: this.props.groups ?? [],
      groups: groups,
      currentGroup: {
        group_title: "",
        group_desc: "",
        questions: [],
        sequenceNumber: 1,
      },
      editingGroupIndex: null,
      groupErrorMessageTitle: "",
      groupErrorMessage: "",
      groupSuccessMessage: "",
      questionErrorMessageTitle: "",
      questionErrorMessage: "",
      questionSuccessMessage: "",
      newQuestion: [{}],
      current_form_id: "",
      selected_group: "",
      all_questions: [],
      unassigned_questions: [],
      add_question_loader: false,
      add_group_loader: false,
      isEditorReady: false,
    };
  }

  getAllQuestions = (groups) => {
    if (groups && groups.length > 0) {
      return groups.flatMap((group) => group.questions);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.forms !== this.state.forms) {
      this.props.onFormContentChange(this.state.forms);
    }

    if (prevState.groups !== this.state.groups) {
      this.props.onGroupsChange(this.state.groups); // Notify parent of group changes
    }
  }

  handleQuestionTextChange = (formId, questionId, value) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? { ...question, questionText: value }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleSequenceChange = (formId, questionId, value) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? { ...question, sequenceNumber: value }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleAnswerTypeChange = (formId, questionId, value) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? { ...question, answerType: value, options: [] }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleDynamicValue = (formId, questionId, value) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? { ...question, dynamicValue: value, options: [] }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleRequiredChange = (formId, questionId, value) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? { ...question, required: value === "true" }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleOptionChange = (formId, questionId, optionIndex, value) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? {
                      ...question,
                      options: question.options.map((option, index) =>
                        index === optionIndex ? value : option
                      ),
                    }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleFixedOption = (formId, questionId, value) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? { ...question, answer: value }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleAddOption = (formId, questionId) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? { ...question, options: [...question.options, ""] }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleDeleteOption = (formId, questionId, optionIndex) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.map((question) =>
                question.id === questionId
                  ? {
                      ...question,
                      options: question.options.filter(
                        (_, index) => index !== optionIndex
                      ),
                    }
                  : question
              ),
            }
          : form
      ),
    }));
  };

  handleAddQuestion = (formId) => {
    const newQuestion = {
      id: uuid(),
      questionText: "",
      answerType: "",
      required: false,
      options: [],
      imageUrl: "",
    };
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? { ...form, questions: [...form.questions, newQuestion] }
          : form
      ),
    }));
  };

  handleDeleteQuestion = (formId, questionId) => {
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? {
              ...form,
              questions: form.questions.filter((q) => q.id !== questionId),
            }
          : form
      ),
    }));
  };

  handleImageUpload = (formId, questionId, event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post(
          `${process.env.REACT_APP_API_URL}forms/form_image_admin`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          const imageUrl = response.data.default;
          this.setState((prevState) => ({
            forms: prevState.forms.map((form) =>
              form.id === formId
                ? {
                    ...form,
                    questions: form.questions.map((question) =>
                      question.id === questionId
                        ? { ...question, imageUrl }
                        : question
                    ),
                  }
                : form
            ),
          }));
        })
        .catch((error) => {
          console.error("There was an error uploading the image!", error);
        });
    }
  };

  handleSaveForm = () => {
    this.props.onFormSubmit();
  };

  handleOpenForm = () => {
    const previewContent = (
      <FormRenderer
        formData={this.state.forms}
        formTitle={this.state.form_title}
        id={this.state.forms[0].id}
      />
    );
    this.setState({ previewContent }, () => {
      window.$("#modalPreviewForm").modal("show");
    });
  };

  handleSelectChange = (selectedOptions) => {
    const { groups, currentGroup, editingGroupIndex } = this.state;
    const updatedCurrentGroup = {
      ...currentGroup,
      questions: selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [],
    };
    const updatedGroups = [...groups];
    updatedGroups[editingGroupIndex] = updatedCurrentGroup;

    this.setState({
      groups: updatedGroups,
      currentGroup: updatedCurrentGroup,
    });
  };

  handleGroupQuestions = () => {
    this.setState((prevState) => ({
      showSelectList: !prevState.showSelectList,
    }));
    const element = document.getElementById("groupEditDiv");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  handleGroupTitleChange = (event) => {
    this.setState((prevState) => ({
      currentGroup: {
        ...prevState.currentGroup,
        group_title: event.target.value,
      },
    }));
  };

  handleGroupDescChange = (event) => {
    this.setState((prevState) => ({
      currentGroup: {
        ...prevState.currentGroup,
        group_desc: event.target.value,
      },
    }));
  };

  handleEditorChange = (updatedContent) => {
    //console.log('updatedContent: ',updatedContent);
    this.setState((prevState) => ({
      currentGroup: {
        ...prevState.currentGroup,
        group_desc: updatedContent,
      },
    }));
    //this.setState({ form_desc: updatedContent });
  };

  handleEditorChangeEdit = (updatedContent) => {
    //console.log('updatedContent: ',updatedContent);
    this.setState((prevState) => ({
      currentGroup: {
        ...prevState.currentGroup,
        group_desc: updatedContent,
      },
    }));
    //this.setState({ form_desc: updatedContent });
  };

  handleAddGroup = () => {
    const { groups, currentGroup, editingGroupIndex } = this.state;

    if (editingGroupIndex !== null) {
      const updatedGroups = [...groups];
      updatedGroups[editingGroupIndex] = currentGroup;
      this.setState({
        groups: updatedGroups,
        currentGroup: {
          group_title: "",
          group_desc: "",
          sequenceNumber: "",
          questions: [],
        },
        showSelectList: false,
        editingGroupIndex: null,
      });
    } else {
      this.setState({
        groups: [...groups, currentGroup],
        currentGroup: {
          id: uuid(),
          group_title: "",
          group_desc: "",
          sequenceNumber: "",
          questions: [],
        },
        showSelectList: false,
      });
    }
  };

  handleGroupSequenceChange = (event) => {
    const sequenceNumber = event.target.value;
    this.setState((prevState) => ({
      currentGroup: {
        ...prevState.currentGroup,
        sequenceNumber: sequenceNumber,
      },
    }));
  };

  handleEditGroup = (index) => {
    const group = this.state.groups[index];
    this.setState({
      editingGroupIndex: index,
      currentGroup: {
        group_title: group.group_title,
        group_desc: group.group_desc,
        sequenceNumber: group.sequenceNumber,
        questions: group.questions,
      },
      showSelectList: true,
    });
    const element = document.getElementById("groupEditDiv");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  handleCancelEdit = () => {
    this.setState({
      currentGroup: {
        group_title: "",
        group_desc: "",
        sequenceNumber: "",
        questions: [],
      },
      showSelectList: false,
      editingGroupIndex: null,
    });
  };

  scrollToDiv = () => {
    const element = document.getElementById("groupEditDiv");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  addGroup = () => {
    /*this.setState((prevState) => ({
      showSelectList: !prevState.showSelectList,
    }));*/
    this.setState(
      {
        currentGroup: {
          id: uuid(),
          group_title: "",
          group_desc: "",
          sequenceNumber: "",
          questions: [],
        },
        add_group_loader: false,
        groupErrorMessageTitle: "",
        groupErrorMessage: "",
        groupSuccessMessage: "",
        isEditorReady: false
      },
      () => {
        setTimeout(() => {
          this.setState({ isEditorReady: true });
        }, 500);
      }
    );
    window.$("#modalAddGroup").modal("show");
  };

  handleAddGroupNew = () => {
    const { groups, currentGroup, editingGroupIndex } = this.state;
    this.setState({
      groups: [...groups, currentGroup],
      currentGroup: {
        group_title: "",
        group_desc: "",
        sequenceNumber: "",
        questions: [],
      },
      add_group_loader: true,
      showSelectList: false,
      groupSuccessMessage: "Group added successfully.",
    });
    setTimeout(() => {
      window.$("#modalAddGroup").modal("hide");
    }, 3000);
  };

  editGroupNew = (index) => {
    const group = this.state.groups[index];
    this.setState(
      {
        editingGroupIndex: index,
        currentGroup: {
          group_title: group.group_title,
          group_desc: group.group_desc,
          sequenceNumber: group.sequenceNumber,
          questions: group.questions,
        },
        groupErrorMessageTitle: "",
        groupErrorMessage: "",
        groupSuccessMessage: "",
        isEditorReady: false
      },
      () => {
        setTimeout(() => {
          this.setState({ isEditorReady: true });
        }, 500);
      }
    );

    window.$("#modalEditGroup").modal("show");
  };

  handleEditGroupNew = () => {
    const { groups, currentGroup, editingGroupIndex } = this.state;
    const updatedGroups = [...groups];
    updatedGroups[editingGroupIndex] = currentGroup;
    this.setState({
      groups: updatedGroups,
      groupSuccessMessage: "Group updated successfully.",
    });
    setTimeout(() => {
      window.$("#modalEditGroup").modal("hide");
    }, 3000);
  };

  addNewQuestion = (formId) => {
    const newQuestion = [
      {
        id: uuid(),
        questionText: "",
        answerType: "",
        required: false,
        options: [],
        imageUrl: "",
        dynamicValue: "",
        sequenceNumber: "",
      },
    ];
    this.setState({
      add_question_loader: false,
      questionErrorMessageTitle: "",
      questionErrorMessage: "",
      questionSuccessMessage: "",
      newQuestion: newQuestion,
      current_form_id: formId,
    });

    window.$("#modalAddQuestion").modal("show");
  };

  handleSequenceChange_new = (questionId, event) => {
    const newSequenceNumber = event.target.value;
    console.log("newSequenceNumber: ", newSequenceNumber);
    this.setState(
      (prevState) => {
        const updatedQuestions = prevState.newQuestion.map((question) => {
          if (question.id === questionId) {
            return { ...question, sequenceNumber: newSequenceNumber };
          }
          return question;
        });

        return { newQuestion: updatedQuestions };
      },
      () => {
        // Callback function to log the state after it has been updated
        console.log("Updated newQuestion state:", this.state.newQuestion);
      }
    );
  };

  handleQuestionTextChange_new = (questionId, event) => {
    const newQuestionText = event.target.value;
    console.log("questionText: ", newQuestionText);
    this.setState(
      (prevState) => {
        const updatedQuestions = prevState.newQuestion.map((question) => {
          if (question.id === questionId) {
            return { ...question, questionText: newQuestionText };
          }
          return question;
        });

        return { newQuestion: updatedQuestions };
      },
      () => {
        // Callback function to log the state after it has been updated
        console.log("Updated newQuestion state:", this.state.newQuestion);
      }
    );
  };

  handleAnswerTypeChange_new = (questionId, event) => {
    const newAnswerType = event.target.value;
    console.log("answerType: ", newAnswerType);
    this.setState(
      (prevState) => {
        const updatedQuestions = prevState.newQuestion.map((question) => {
          if (question.id === questionId) {
            return { ...question, answerType: newAnswerType, options: [] };
          }
          return question;
        });

        return { newQuestion: updatedQuestions };
      },
      () => {
        // Callback function to log the state after it has been updated
        console.log("Updated newQuestion state:", this.state.newQuestion);
      }
    );
  };

  handleDynamicValue_new = (questionId, event) => {
    const dynamicValue = event.target.value;
    console.log("dynamicValue: ", dynamicValue);
    this.setState(
      (prevState) => {
        const updatedQuestions = prevState.newQuestion.map((question) => {
          if (question.id === questionId) {
            return { ...question, dynamicValue: dynamicValue };
          }
          return question;
        });

        return { newQuestion: updatedQuestions };
      },
      () => {
        // Callback function to log the state after it has been updated
        console.log("Updated newQuestion state:", this.state.newQuestion);
      }
    );
  };

  handleRequiredChange_new = (questionId, event) => {
    const newRequired = event.target.value;
    console.log("dynamicValue: ", newRequired);
    this.setState(
      (prevState) => {
        const updatedQuestions = prevState.newQuestion.map((question) => {
          if (question.id === questionId) {
            return { ...question, required: newRequired === "true" };
          }
          return question;
        });

        return { newQuestion: updatedQuestions };
      },
      () => {
        // Callback function to log the state after it has been updated
        console.log("Updated newQuestion state:", this.state.newQuestion);
      }
    );
  };

  handleOptionChange_new = (questionId, optionIndex, event) => {
    const newRequired = event.target.value;
    console.log("dynamicValue: ", newRequired);
    this.setState(
      (prevState) => {
        const updatedQuestions = prevState.newQuestion.map((question) => {
          if (question.id === questionId) {
            return {
              ...question,
              options: question.options.map((option, index) =>
                index === optionIndex ? newRequired : option
              ),
            };
          }
          return question;
        });

        return { newQuestion: updatedQuestions };
      },
      () => {
        // Callback function to log the state after it has been updated
        console.log("Updated newQuestion state:", this.state.newQuestion);
      }
    );
  };

  handleDeleteOption_new = (questionId, optionIndex) => {
    this.setState(
      (prevState) => {
        const updatedQuestions = prevState.newQuestion.map((question) => {
          if (question.id === questionId) {
            return {
              ...question,
              options: question.options.filter(
                (_, index) => index !== optionIndex
              ),
            };
          }
          return question;
        });

        return { newQuestion: updatedQuestions };
      },
      () => {
        // Callback function to log the state after it has been updated
        console.log("Updated newQuestion state:", this.state.newQuestion);
      }
    );
  };

  handleFixedOption_new = (questionId, event) => {
    const newRequired = event.target.value;
    console.log("dynamicValue: ", newRequired);
    this.setState(
      (prevState) => {
        const updatedQuestions = prevState.newQuestion.map((question) => {
          if (question.id === questionId) {
            return { ...question, answer: newRequired };
          }
          return question;
        });

        return { newQuestion: updatedQuestions };
      },
      () => {
        // Callback function to log the state after it has been updated
        console.log("Updated newQuestion state:", this.state.newQuestion);
      }
    );
  };

  handleAddOption_new = (questionId) => {
    this.setState(
      (prevState) => {
        const updatedQuestions = prevState.newQuestion.map((question) => {
          if (question.id === questionId) {
            return { ...question, options: [...question.options, ""] };
          }
          return question;
        });

        return { newQuestion: updatedQuestions };
      },
      () => {
        // Callback function to log the state after it has been updated
        console.log("Updated newQuestion state:", this.state.newQuestion);
      }
    );
  };

  submitNewQuestion = () => {
    const { newQuestion, current_form_id, selected_group } = this.state;
    console.log("submitNewQuestion selected_group: ", selected_group);
    console.log("submitNewQuestion newQuestion: ", newQuestion[0]);
    this.setState({ add_question_loader: true });
    let questionText = "";
    let answerType = "";
    if (newQuestion && newQuestion.length > 0) {
      questionText = newQuestion[0].questionText;
      answerType = newQuestion[0].answerType;
    }
    if (questionText === "") {
      this.setState({
        questionErrorMessageTitle: "Missing Input",
        questionErrorMessage: "Please enter the question text.",
        questionSuccessMessage: "",
        add_question_loader: false,
      });
    } else if (answerType === "") {
      this.setState({
        questionErrorMessageTitle: "Missing Input",
        questionErrorMessage: "Please select answer type.",
        questionSuccessMessage: "",
        add_question_loader: false,
      });
    } else if (selected_group === "") {
      this.setState({
        questionErrorMessageTitle: "Missing Input",
        questionErrorMessage: "Please select group.",
        questionSuccessMessage: "",
        add_question_loader: false,
      });
    } else {
      //UPDATE GROUPS

      //UPDATE FORMS

      this.setState(
        (prevState) => {
          const formExists = prevState.forms.some(
            (form) => form.id === current_form_id
          );
          if (!formExists) {
            console.error("Form with the given ID does not exist.");
            return prevState; // No changes to state
          }
          const updatedForms = prevState.forms.map((form) =>
            form.id === current_form_id
              ? { ...form, questions: [...form.questions, newQuestion[0]] }
              : form
          );

          console.log("Updated Forms state:", updatedForms); // Log updated forms state before setting it

          return { forms: updatedForms };
        },
        () => {
          // Callback function to log the state after it has been updated
          console.log("State after setState:", this.state.forms);
        }
      );

      this.setState(
        (prevState) => {
          // Update the groups array
          const updatedGroups = prevState.groups.map((group) => {
            // Check if this is the group to update
            if (group.id === selected_group) {
              // Replace `selectedGroupId` with the actual ID of the group you're updating
              return {
                ...group,
                questions: [...group.questions, newQuestion[0].id], // Add new question ID to the array
              };
            }
            return group; // Return other groups unchanged
          });

          // Return the updated state
          return { groups: updatedGroups };
        },
        () => {
          // Callback to log the state after update
          console.log("Updated Groups state:", this.state.groups);
        }
      );

      this.setState({
        questionErrorMessageTitle: "",
        questionErrorMessage: "",
        questionSuccessMessage: "Question added successfully.",
      });
      setTimeout(() => {
        this.setState({
          questionErrorMessageTitle: "",
          questionErrorMessage: "",
          questionSuccessMessage: "",
        });
        window.$("#modalAddQuestion").modal("hide");
      }, 3000);
    }
    setTimeout(() => {
      this.setState({
        questionErrorMessageTitle: "",
        questionErrorMessage: "",
        questionSuccessMessage: "",
      });
    }, 3000);
  };

  handleAddQuestion2 = (formId) => {
    const newQuestion = {
      id: uuid(),
      questionText: "",
      answerType: "",
      required: false,
      options: [],
      imageUrl: "",
    };
    this.setState((prevState) => ({
      forms: prevState.forms.map((form) =>
        form.id === formId
          ? { ...form, questions: [...form.questions, newQuestion] }
          : form
      ),
    }));
  };

  closeModal = (modal_id) => {
    window.$("#" + modal_id).modal("hide");
  };

  checkIfIdExists = (idArray, id) => {
    return idArray.includes(id);
  };

  checkIfIdDoesNotExist = (groups, idToCheck) => {
    // Initialize a flag to indicate if the ID is found
    let idFound = false;

    // Iterate over each group
    for (const group of groups) {
      // Check if the questions array includes the ID
      if (
        Array.isArray(group.questions) &&
        group.questions.includes(idToCheck)
      ) {
        idFound = true;
        break;
      }
    }

    // If the ID was not found, handle it here
    if (!idFound) {
      //console.log(`ID "${idToCheck}" does not exist in any questions array.`);
      return true;
    } else {
      //console.log(`ID "${idToCheck}" exists in one of the questions arrays.`);
      return false;
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { classes } = this.props;
    const { forms } = this.state;

    return (
      <div>
        <div class="example-alert mb-4">
          <div class="alert alert-gray alert-icon">
            <em class="icon ni ni-alert-circle"></em>{" "}
            <strong>
              Note: Please "Save Form" regularly to protect your data as you add
              groups or questions. Avoid refreshing the page without saving your
              changes.
            </strong>
          </div>
        </div>
        <div className="border-bottom mb-4 pb-4">
          <div class="nk-block-between-md g-4">
            <div class="nk-block-head-content">
              <h3 class="nk-block-title fw-normal">Groups</h3>
            </div>
            <div class="nk-block-head-content">
              <ul class="nk-block-tools gx-3">
                <li class="order-md-last">
                  <a
                    onClick={() => this.addGroup()}
                    class="btn btn-primary"
                    style={{ color: "#fff", cursor: "pointer" }}
                  >
                    <em class="icon ni ni-plus"></em>
                    <span>Add New Group</span>
                  </a>
                </li>
                {forms.map((form) => (
                  <li className="order-md-last">
                    <a
                      style={{ cursor: "pointer", color: "#fff" }}
                      onClick={() => this.addNewQuestion(form.id)}
                      className={`btn btn-primary`}
                    >
                      <em class="icon ni ni-plus"></em>
                      <span>Add New Question</span>
                    </a>
                  </li>
                ))}
                <li className="order-md-last">
                  <a
                    style={{ cursor: "pointer", color: "#fff" }}
                    onClick={this.handleSaveForm}
                    className="btn btn-success"
                  >
                    <em className="icon ni ni-save"></em>
                    &nbsp;Save Form
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {this.state.groups && this.state.groups.length > 0 ? (
            <div id="accordion" class="accordion mt-4">
              {Array.isArray(this.state.groups) &&
                this.state.groups.map((group, index) => (
                  <div class="accordion-item" key={`group_${index}`}>
                    <a
                      href="#"
                      class={
                        index === 0
                          ? "accordion-head"
                          : "accordion-head collapsed"
                      }
                      data-toggle="collapse"
                      data-target={`#accordion-items-${index}`}
                      style={{ backgroundColor: "#f5f6fa" }}
                    >
                      <h6 class="title">
                        {group.sequenceNumber}. {group.group_title}{" "}
                        <span class="badge badge-dim badge-primary badge-pill">
                          <em
                            className="icon ni ni-edit"
                            style={{ cursor: "pointer", fontSize: "16px" }}
                            onClick={() => this.editGroupNew(index)}
                          ></em>
                        </span>
                      </h6>
                      <span class="accordion-icon"></span>
                    </a>
                    <div
                      class={
                        index === 0
                          ? "accordion-body collapse show"
                          : "accordion-body collapse"
                      }
                      id={`accordion-items-${index}`}
                      data-parent="#accordion"
                    >
                      <div class="accordion-inner">
                        <strong style={{ color: "#1c2b46" }}>
                          Assigned Fields
                        </strong>
                        {/*<div class="sp-pdl-meta">
                          {group.questions.map((questionId, ind) => {
                            const question = forms[0].questions.find(
                              (q) => q.id === questionId
                            );
                            return question ? (
                              <span
                                class="release"
                                key={`question_${questionId}`}
                              >
                                <span
                                  class="text-body"
                                  style={{ color: "#1c2b46" }}
                                >
                                  <strong>{ind + 1}.</strong>{" "}
                                  {question.questionText}
                                </span>
                              </span>
                            ) : null;
                          })}
                        </div>*/}
                        <div className="mt-4">
                          {forms.map((form) => (
                            <div
                              key={`form_keys_${form.id}`}
                              className={classes.formContainer}
                            >
                              {form.questions.map((question, indexes) => (
                                <>
                                  {this.checkIfIdExists(
                                    group.questions,
                                    question.id
                                  ) && (
                                    <div
                                      key={`input_key_${question.id}`}
                                      className={classes.questionContainer}
                                    >
                                      <div
                                        className={`row g-4 ${classes.questionTop}`}
                                      >
                                        <div className="col-lg-1">
                                          <MutextField
                                            label="Seq #"
                                            value={
                                              question.sequenceNumber &&
                                              question.sequenceNumber !== ""
                                                ? question.sequenceNumber
                                                : ""
                                            }
                                            onChange={(e) =>
                                              this.handleSequenceChange(
                                                form.id,
                                                question.id,
                                                e.target.value
                                              )
                                            }
                                            variant="outlined"
                                            fullWidth
                                          />
                                        </div>
                                        <div className="col-lg-4">
                                          <div className="form-group">
                                            <MutextField
                                              label="Question Text"
                                              value={question.questionText}
                                              onChange={(e) =>
                                                this.handleQuestionTextChange(
                                                  form.id,
                                                  question.id,
                                                  e.target.value
                                                )
                                              }
                                              variant="outlined"
                                              fullWidth
                                            />
                                          </div>
                                        </div>
                                        <div className="col-lg-2">
                                          <div className="form-group">
                                            <MutextField
                                              id={`answer-type-label-${question.id}`}
                                              name={`answer-type-label-${question.id}`}
                                              select
                                              label="Answer Type"
                                              value={question.answerType}
                                              onChange={(e) =>
                                                this.handleAnswerTypeChange(
                                                  form.id,
                                                  question.id,
                                                  e.target.value
                                                )
                                              }
                                              SelectProps={{
                                                native: true,
                                              }}
                                              variant="outlined"
                                              fullWidth
                                            >
                                              <option key="" value=""></option>
                                              <option key="input" value="input">
                                                Input
                                              </option>
                                              <option
                                                key="textarea"
                                                value="textarea"
                                              >
                                                Textarea
                                              </option>
                                              <option
                                                key="dropdown"
                                                value="dropdown"
                                              >
                                                Dropdown
                                              </option>
                                              <option key="input" value="label">
                                                Label
                                              </option>
                                              <option key="input" value="fixed">
                                                Fixed Fields
                                              </option>
                                              <option
                                                key="input"
                                                value="signature"
                                              >
                                                Signature
                                              </option>
                                              <option
                                                key="checkbox"
                                                value="checkbox"
                                              >
                                                Checkbox
                                              </option>
                                              <option key="radio" value="radio">
                                                Radio
                                              </option>
                                              <option
                                                key="image_input"
                                                value="image_input"
                                              >
                                                Image Input
                                              </option>
                                              <option
                                                key="datetime"
                                                value="datetime"
                                              >
                                                Datetime
                                              </option>
                                              <option key="date" value="date">
                                                Date
                                              </option>
                                              <option key="time" value="time">
                                                Time
                                              </option>
                                            </MutextField>
                                          </div>
                                        </div>
                                        <div className="col-lg-2">
                                          <div className="form-group">
                                            <MutextField
                                              id={`dynamic-value-${question.id}`}
                                              name={`dynamic-value-${question.id}`}
                                              select
                                              label="Dynamic Value"
                                              value={question.dynamicValue}
                                              onChange={(e) =>
                                                this.handleDynamicValue(
                                                  form.id,
                                                  question.id,
                                                  e.target.value
                                                )
                                              }
                                              SelectProps={{
                                                native: true,
                                              }}
                                              variant="outlined"
                                              fullWidth
                                            >
                                              <option key="" value=""></option>
                                              <option
                                                key="fullname"
                                                value="fullname"
                                              >
                                                Full Name
                                              </option>
                                              <option
                                                key="company"
                                                value="company"
                                              >
                                                Company
                                              </option>
                                              <option key="email" value="email">
                                                Email Address
                                              </option>
                                              <option key="phone" value="phone">
                                                Phone
                                              </option>
                                              <option
                                                key="full_address"
                                                value="full_address"
                                              >
                                                Full Address
                                              </option>
                                              <option
                                                key="address"
                                                value="address"
                                              >
                                                Address
                                              </option>
                                              <option
                                                key="appartment"
                                                value="appartment"
                                              >
                                                Appartment, Suite
                                              </option>
                                              <option key="city" value="city">
                                                City
                                              </option>
                                              <option key="state" value="state">
                                                State
                                              </option>
                                              <option key="zip" value="zip">
                                                Zip
                                              </option>
                                              <option
                                                key="country"
                                                value="country"
                                              >
                                                Country
                                              </option>
                                            </MutextField>
                                          </div>
                                        </div>
                                        <div className="col-lg-1">
                                          <div className="form-group">
                                            <MutextField
                                              id={`required-label-${question.id}`}
                                              name={`required-label-${question.id}`}
                                              select
                                              label="Required"
                                              value={question.required.toString()}
                                              onChange={(e) =>
                                                this.handleRequiredChange(
                                                  form.id,
                                                  question.id,
                                                  e.target.value
                                                )
                                              }
                                              SelectProps={{
                                                native: true,
                                              }}
                                              variant="outlined"
                                              fullWidth
                                            >
                                              <option key="false" value="false">
                                                No
                                              </option>
                                              <option key="true" value="true">
                                                Yes
                                              </option>
                                            </MutextField>
                                          </div>
                                        </div>
                                        <div
                                          className="col-lg-2"
                                          style={{
                                            alignContent: "center",
                                            display: "flex",
                                          }}
                                        >
                                          {question.answerType !==
                                          "image_input" ? (
                                            <div
                                              style={{ alignContent: "center" }}
                                            >
                                              <input
                                                id={`image-upload-${question.id}`}
                                                type="file"
                                                accept=".png, .jpg, .jpeg, .pdf, .txt, .csv, .xls, .xlsx, .doc, .docx"
                                                style={{ display: "none" }}
                                                onChange={(e) =>
                                                  this.handleImageUpload(
                                                    form.id,
                                                    question.id,
                                                    e
                                                  )
                                                }
                                              />
                                              <button
                                                className={`btn btn-dim btn-primary ${classes.deleteButton}`}
                                                onClick={() => {
                                                  const fileInput =
                                                    document.getElementById(
                                                      `image-upload-${question.id}`
                                                    );
                                                  if (fileInput) {
                                                    fileInput.click();
                                                  }
                                                }}
                                              >
                                                Upload File
                                              </button>
                                            </div>
                                          ) : null}
                                          <div
                                            className=""
                                            style={{ alignContent: "center" }}
                                          >
                                            <a
                                              className={`btn btn-dim btn-danger ${classes.deleteButton}`}
                                              onClick={() =>
                                                this.handleDeleteQuestion(
                                                  form.id,
                                                  question.id
                                                )
                                              }
                                            >
                                              Delete
                                            </a>
                                          </div>
                                        </div>
                                        {question.imageUrl && (
                                          <div className="col-lg-12 text-primary">
                                            {question.imageUrl}
                                          </div>
                                        )}
                                        {question.answerType === "checkbox" ||
                                        question.answerType === "radio" ||
                                        question.answerType === "dropdown" ? (
                                          <div className="col-lg-6">
                                            {question.options.map(
                                              (option, index) => (
                                                <div
                                                  key={index}
                                                  className={
                                                    classes.optionContainer
                                                  }
                                                >
                                                  <MutextField
                                                    label={`Option ${
                                                      index + 1
                                                    }`}
                                                    value={option}
                                                    onChange={(e) =>
                                                      this.handleOptionChange(
                                                        form.id,
                                                        question.id,
                                                        index,
                                                        e.target.value
                                                      )
                                                    }
                                                    variant="outlined"
                                                    fullWidth
                                                  />
                                                  <a
                                                    className={`btn btn-dim btn-danger ${classes.deleteButton}`}
                                                    onClick={() =>
                                                      this.handleDeleteOption(
                                                        form.id,
                                                        question.id,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </a>
                                                </div>
                                              )
                                            )}
                                            <a
                                              className={`btn btn-outline-light`}
                                              onClick={() =>
                                                this.handleAddOption(
                                                  form.id,
                                                  question.id
                                                )
                                              }
                                              style={{ marginTop: "20px" }}
                                            >
                                              Add Option
                                            </a>
                                          </div>
                                        ) : null}
                                        {question.answerType ===
                                        "image_input" ? (
                                          <div className="col-lg-4">
                                            <input
                                              type="file"
                                              accept="image/*"
                                              disabled
                                            />
                                          </div>
                                        ) : null}
                                        {question.answerType === "fixed" ? (
                                          <div className="col-lg-7">
                                            <div className="form-group">
                                              <MutextField
                                                label="Value"
                                                value={question.answer}
                                                onChange={(e) =>
                                                  this.handleFixedOption(
                                                    form.id,
                                                    question.id,
                                                    e.target.value
                                                  )
                                                }
                                                variant="outlined"
                                                fullWidth
                                              />
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  )}
                                </>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ) : null}

          {/*<ul class="sp-pdl-list">
            {Array.isArray(this.state.groups) &&
              this.state.groups.map((group, index) => (
                <li class="sp-pdl-item" key={index}>
                  <div class="sp-pdl-desc">
                    <div class="sp-pdl-info">
                      <h6 class="sp-pdl-title">
                        <span class="sp-pdl-name">{group.group_title}</span>{" "}
                        <span class="badge badge-dim badge-primary badge-pill">
                          <em
                            className="icon ni ni-edit"
                            style={{ cursor: "pointer", fontSize: "16px" }}
                            onClick={() => this.handleEditGroup(index)}
                          ></em>
                        </span>
                      </h6>
                      <strong style={{ color: "#1c2b46" }}>
                        Assigned Fields
                      </strong>
                      <div class="sp-pdl-meta">
                        {group.questions.map((questionId, ind) => {
                          const question = forms[0].questions.find(
                            (q) => q.id === questionId
                          );
                          return question ? (
                            <span class="release" key={questionId}>
                              <span
                                class="text-body"
                                style={{ color: "#1c2b46" }}
                              >
                                <strong>{ind + 1}.</strong>{" "}
                                {question.questionText}
                              </span>
                            </span>
                          ) : null;
                        })}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
            
            <>                           
          {Array.isArray(this.state.groups) &&
            this.state.groups.map((group, index) => (
              <div key={index} className="border-top pt-4 pb-4">
                <h5>
                  {group.group_title}{" "}
                  <em
                    className="icon ni ni-edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleEditGroup(index)}
                  ></em>{" "}
                </h5>

                <p>{group.group_desc}</p>
                <h6>Assigned Form Fields</h6>
                <ul>
                  {group.questions.map((questionId) => {
                    const question = forms[0].questions.find(
                      (q) => q.id === questionId
                    );
                    return question ? (
                      <li key={questionId}>{question.questionText}</li>
                    ) : null;
                  })}
                </ul>
              </div>
            ))}</>*/}
        </div>
        {/*<ul className="nk-block-tools g-3 mb-4">
          <li className="nk-block-tools-opt">
            <a
              style={{ cursor: "pointer", color: "#fff" }}
              onClick={() => this.handleGroupQuestions()}
              className={`btn btn-primary ${classes.addButton}`}
            >
              <AddIcon />
              &nbsp;Add New Group
            </a>
          </li>
        </ul>
        {forms[0].questions.length > 0 && (
          <div id="groupEditDiv">
            {this.state.showSelectList && (
              <div className="col-6 mt-4">
                <h2>Groups</h2>
                <MutextField
                  required
                  type="text"
                  label="Group Title"
                  value={this.state.currentGroup.group_title}
                  onChange={this.handleGroupTitleChange}
                  inputProps={{ maxLength: 100 }}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />
                <MutextField
                  required
                  type="number"
                  label="Group Sequence #"
                  value={this.state.currentGroup.sequenceNumber}
                  onChange={this.handleGroupSequenceChange}
                  inputProps={{ maxLength: 10 }}
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />
                <MutextField
                  required
                  type="text"
                  label="Group Description"
                  value={this.state.currentGroup.group_desc}
                  onChange={this.handleGroupDescChange}
                  inputProps={{ maxLength: 1000 }}
                  helperText="Maximum 1000 characters"
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: "20px" }}
                />
                <Select2
                  isMulti
                  name="questions"
                  options={forms[0].questions
                    .filter((question) => {
                      const questionId = question.id;
                      return (
                        !this.state.groups
                          .flatMap((group) => group.questions)
                          .includes(questionId) ||
                        this.state.currentGroup.questions.includes(questionId)
                      );
                    })
                    .map((question) => ({
                      value: question.id,
                      label: question.questionText,
                    }))}
                  onChange={this.handleSelectChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={forms[0].questions
                    .filter((question) =>
                      this.state.currentGroup.questions.includes(question.id)
                    )
                    .map((question) => ({
                      value: question.id,
                      label: question.questionText,
                    }))}
                  placeholder="Select questions"
                />
                <ul className="nk-block-tools g-3">
                  <li className="nk-block-tools-opt">
                    <a
                      style={{ cursor: "pointer", color: "#fff" }}
                      onClick={() => this.handleAddGroup()}
                      className={`btn btn-primary ${classes.addButton}`}
                    >
                      <AddIcon />
                      &nbsp;Add Group
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}*/}
        <h5 className="mt-4 mb-4">Form fields not assigned to the groups</h5>
        <div>
          {forms.map((form) => (
            <div
              key={`form_key_${form.id}`}
              className={`mt-4 ${classes.formContainer}`}
            >
              {form.questions.map((question, indexes) => (
                <>
                  {this.checkIfIdDoesNotExist(
                    this.state.groups,
                    question.id
                  ) && (
                    <div
                      key={`input_key_${question.id}`}
                      className={classes.questionContainer}
                    >
                      <div className={`row g-4 ${classes.questionTop}`}>
                        <div className="col-lg-1">
                          <div class="nk-kycfm-count">{indexes + 1}</div>
                        </div>
                        <div className="col-lg-5">
                          <div className="form-group">
                            <MutextField
                              label="Question Text"
                              value={question.questionText}
                              onChange={(e) =>
                                this.handleQuestionTextChange(
                                  form.id,
                                  question.id,
                                  e.target.value
                                )
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="form-group">
                            <MutextField
                              id={`answer-type-label-${question.id}`}
                              name={`answer-type-label-${question.id}`}
                              select
                              label="Answer Type"
                              value={question.answerType}
                              onChange={(e) =>
                                this.handleAnswerTypeChange(
                                  form.id,
                                  question.id,
                                  e.target.value
                                )
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                              fullWidth
                            >
                              <option key="" value=""></option>
                              <option key="input" value="input">
                                Input
                              </option>
                              <option key="textarea" value="textarea">
                                Textarea
                              </option>
                              <option key="dropdown" value="dropdown">
                                Dropdown
                              </option>
                              <option key="input" value="label">
                                Label
                              </option>
                              <option key="input" value="fixed">
                                Fixed Fields
                              </option>
                              <option key="input" value="signature">
                                Signature
                              </option>
                              <option key="checkbox" value="checkbox">
                                Checkbox
                              </option>
                              <option key="radio" value="radio">
                                Radio
                              </option>
                              <option key="image_input" value="image_input">
                                Image Input
                              </option>
                              <option key="datetime" value="datetime">
                                Datetime
                              </option>
                              <option key="date" value="date">
                                Date
                              </option>
                              <option key="time" value="time">
                                Time
                              </option>
                            </MutextField>
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="form-group">
                            <MutextField
                              id={`dynamic-value-${question.id}`}
                              name={`dynamic-value-${question.id}`}
                              select
                              label="Dynamic Value"
                              value={question.dynamicValue}
                              onChange={(e) =>
                                this.handleDynamicValue(
                                  form.id,
                                  question.id,
                                  e.target.value
                                )
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                              fullWidth
                            >
                              <option key="" value=""></option>
                              <option key="fullname1" value="fullname">
                                Full Name
                              </option>
                              <option key="company1" value="company">
                                Company
                              </option>
                              <option key="email1" value="email">
                                Email Address
                              </option>
                              <option key="phone1" value="phone">
                                Phone
                              </option>
                              <option key="full_address1" value="full_address">
                                Full Address
                              </option>
                              <option key="address1" value="address">
                                Address
                              </option>
                              <option key="appartment1" value="appartment">
                                Appartment, Suite
                              </option>
                              <option key="city1" value="city">
                                City
                              </option>
                              <option key="state1" value="state">
                                State
                              </option>
                              <option key="zip1" value="zip">
                                Zip
                              </option>
                              <option key="country1" value="country">
                                Country
                              </option>
                            </MutextField>
                          </div>
                        </div>
                        <div className="col-lg-1">
                          <div className="form-group">
                            <MutextField
                              id={`required-label-${question.id}`}
                              name={`required-label-${question.id}`}
                              select
                              label="Required"
                              value={question.required.toString()}
                              onChange={(e) =>
                                this.handleRequiredChange(
                                  form.id,
                                  question.id,
                                  e.target.value
                                )
                              }
                              SelectProps={{
                                native: true,
                              }}
                              variant="outlined"
                              fullWidth
                            >
                              <option key="false" value="false">
                                No
                              </option>
                              <option key="true" value="true">
                                Yes
                              </option>
                            </MutextField>
                          </div>
                        </div>
                        <div
                          className="col-lg-1"
                          style={{ alignContent: "center", display: "flex" }}
                        >
                          <div className="" style={{ alignContent: "center" }}>
                            <a
                              className={`btn btn-dim btn-danger ${classes.deleteButton}`}
                              onClick={() =>
                                this.handleDeleteQuestion(form.id, question.id)
                              }
                            >
                              Delete
                            </a>
                          </div>
                        </div>
                        {question.imageUrl && (
                          <div className="col-lg-12">
                            <img
                              src={question.imageUrl}
                              alt="Admin Uploaded"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "200px",
                                display: "block",
                              }}
                            />
                          </div>
                        )}
                        {question.answerType === "checkbox" ||
                        question.answerType === "radio" ||
                        question.answerType === "dropdown" ? (
                          <div className="col-lg-6">
                            {question.options.map((option, index) => (
                              <div
                                key={index}
                                className={classes.optionContainer}
                              >
                                <MutextField
                                  label={`Option ${index + 1}`}
                                  value={option}
                                  onChange={(e) =>
                                    this.handleOptionChange(
                                      form.id,
                                      question.id,
                                      index,
                                      e.target.value
                                    )
                                  }
                                  variant="outlined"
                                  fullWidth
                                />
                                <a
                                  className={`btn btn-dim btn-danger ${classes.deleteButton}`}
                                  onClick={() =>
                                    this.handleDeleteOption(
                                      form.id,
                                      question.id,
                                      index
                                    )
                                  }
                                >
                                  Delete
                                </a>
                              </div>
                            ))}
                            <a
                              className={`btn btn-outline-light`}
                              onClick={() =>
                                this.handleAddOption(form.id, question.id)
                              }
                              style={{ marginTop: "20px" }}
                            >
                              Add Option
                            </a>
                          </div>
                        ) : null}
                        {question.answerType === "image_input" ? (
                          <div className="col-lg-4">
                            <input type="file" accept="image/*" disabled />
                          </div>
                        ) : null}
                        {question.answerType === "fixed" ? (
                          <div className="col-lg-7">
                            <div className="form-group">
                              <MutextField
                                label="Value"
                                value={question.answer}
                                onChange={(e) =>
                                  this.handleFixedOption(
                                    form.id,
                                    question.id,
                                    e.target.value
                                  )
                                }
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                </>
              ))}
              {/*<ul className="nk-block-tools g-3">
                <li className="nk-block-tools-opt">
                  <a
                    style={{ cursor: "pointer", color: "#fff" }}
                    onClick={() => this.handleAddQuestion(form.id)}
                    className={`btn btn-primary ${classes.addButton}`}
                  >
                    <AddIcon />
                    &nbsp;Add Question
                  </a>
                </li>
              </ul>*/}
            </div>
          ))}
        </div>

        <div className="button-container border-top pt-4">
          <ul className="nk-block-tools g-3" style={{ justifyContent: "End" }}>
            {/*<li className="nk-block-tools-opt">
              <a
                style={{ cursor: "pointer", color: "#fff" }}
                onClick={this.handleOpenForm}
                className="btn btn-primary"
              >
                <em className="icon ni ni-card-view"></em>
                &nbsp;Preview Form
              </a>
            </li>*/}
            <li className="nk-block-tools-opt">
              <a
                style={{ cursor: "pointer", color: "#fff" }}
                onClick={this.handleSaveForm}
                className="btn btn-success"
              >
                <em className="icon ni ni-save"></em>
                &nbsp;Save Form
              </a>
            </li>
          </ul>
        </div>
        <div className="modal fade" tabIndex="-1" id="modalPreviewForm">
          <div
            className="modal-dialog modal-lg modal-dialog-top"
            role="document"
          >
            <div className="modal-content">
              <a
                style={{ cursor: "pointer" }}
                className="close"
                onClick={() => window.$("#modalPreviewForm").modal("hide")}
              >
                <em className="icon ni ni-cross"></em>
              </a>
              <div className="modal-body">
                <div className="nk-modal">{this.state.previewContent}</div>
              </div>
            </div>
          </div>
        </div>
        {/*** ADD GROUP MODAL ***/}
        <div class="modal fade" tabindex="-1" id="modalAddGroup">
          <div class="modal-dialog  modal-xl modal-dialog-top" role="document">
            <div class="modal-content">
              <a href="#" class="close" data-dismiss="modal" aria-label="Close">
                <em class="icon ni ni-cross"></em>
              </a>
              <div class="modal-header">
                <h5 class="modal-title">
                  <em class="icon ni ni-plus"></em> <span>Add Group</span>
                </h5>
              </div>
              <div class="modal-body">
                <div id="groupEditDiv">
                  <div className="col-12">
                    {this.state.groupErrorMessage !== "" ? (
                      <div className="example-alert mb-4">
                        <div className="alert alert-pro alert-danger">
                          <div className="alert-text">
                            <h4>{this.state.groupErrorMessageTitle}</h4>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: this.state.groupErrorMessage,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.groupSuccessMessage !== "" ? (
                      <div className="example-alert mb-4">
                        <div className="alert alert-pro alert-success">
                          <div className="alert-text">
                            <h4>Success</h4>
                            <p>{this.state.groupSuccessMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <MutextField
                      required
                      type="text"
                      label="Group Title"
                      value={this.state.currentGroup.group_title}
                      onChange={this.handleGroupTitleChange}
                      inputProps={{ maxLength: 100 }}
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "20px" }}
                    />
                    <MutextField
                      required
                      type="number"
                      label="Group Sequence #"
                      value={this.state.currentGroup.sequenceNumber}
                      onChange={this.handleGroupSequenceChange}
                      inputProps={{ maxLength: 10 }}
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "20px" }}
                    />
                    {/*<MutextField
                      required
                      type="text"
                      label="Group Description"
                      value={this.state.currentGroup.group_desc}
                      onChange={this.handleGroupDescChange}
                      inputProps={{ maxLength: 1000 }}
                      helperText="Maximum 1000 characters"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "20px" }}
                    />*/}
                    <div class="form-group">
                      <label class="form-label" for="group_desc1">
                        Description
                      </label>
                      <div class="form-control-wrap">
                        {this.state.isEditorReady ? (
                          <>
                            <RichTextEditorComponent
                              agreementContent={
                                this.state.currentGroup.group_desc
                              }
                              onContentChange={this.handleEditorChange}
                            />
                          </>
                        ) : null}
                        {/*<textarea
                          class="form-control"
                          id="group_desc1"
                          name="group_desc"
                          maxLength={1000}
                          onChange={this.handleGroupDescChange}
                          value={this.state.currentGroup.group_desc}
                        ></textarea>*/}
                      </div>
                    </div>
                    <Select2
                      isMulti
                      name="questions"
                      options={forms[0].questions
                        .filter((question) => {
                          const questionId = question.id;
                          return (
                            !this.state.groups
                              .flatMap((group) => group.questions)
                              .includes(questionId) ||
                            this.state.currentGroup.questions.includes(
                              questionId
                            )
                          );
                        })
                        .map((question) => ({
                          value: question.id,
                          label: question.questionText,
                        }))}
                      onChange={this.handleSelectChange}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={forms[0].questions
                        .filter((question) =>
                          this.state.currentGroup.questions.includes(
                            question.id
                          )
                        )
                        .map((question) => ({
                          value: question.id,
                          label: question.questionText,
                        }))}
                      placeholder="Select questions"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minHeight: "56px",
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          zIndex: 9,
                        }),
                      }}
                    />
                    <div class="d-flex justify-content-end">
                      <ul className="nk-block-tools g-3">
                        {this.state.add_group_loader === true ? (
                          <li className="nk-block-tools-opt  mt-3">
                            <button className="btn btn-lg btn-primary" disabled>
                              <em className="icon ni ni-swap"></em> Saving
                              <div
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </button>
                          </li>
                        ) : (
                          <>
                            <li className="nk-block-tools-opt  mt-3">
                              <a
                                style={{ cursor: "pointer", color: "#fff" }}
                                onClick={() => this.closeModal("modalAddGroup")}
                                className={`btn btn-primary btn-lg`}
                              >
                                <em class="icon ni ni-shrink"></em>
                                <span>Cancel</span>
                              </a>
                            </li>
                            <li className="nk-block-tools-opt  mt-3">
                              <a
                                style={{ cursor: "pointer", color: "#fff" }}
                                onClick={() => this.handleAddGroupNew()}
                                className={`btn btn-primary  btn-lg`}
                              >
                                <em class="icon ni ni-plus"></em>
                                <span>Add Group</span>
                              </a>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*** ADD GROUP MODAL ***/}
        {/*** ADD GROUP MODAL ***/}
        <div class="modal fade" tabindex="-1" id="modalEditGroup">
          <div class="modal-dialog  modal-xl modal-dialog-top" role="document">
            <div class="modal-content">
              <a href="#" class="close" data-dismiss="modal" aria-label="Close">
                <em class="icon ni ni-cross"></em>
              </a>
              <div class="modal-header">
                <h5 class="modal-title">
                  <em class="icon ni ni-edit"></em> <span>Edit Group</span>
                </h5>
              </div>
              <div class="modal-body">
                <div id="groupEditDiv1">
                  <div className="col-12">
                    <MutextField
                      required
                      type="text"
                      label="Group Title"
                      value={this.state.currentGroup.group_title}
                      onChange={this.handleGroupTitleChange}
                      inputProps={{ maxLength: 100 }}
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "20px" }}
                    />
                    <MutextField
                      required
                      type="number"
                      label="Group Sequence #"
                      value={this.state.currentGroup.sequenceNumber}
                      onChange={this.handleGroupSequenceChange}
                      inputProps={{ maxLength: 10 }}
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "20px" }}
                    />
                    {/*<MutextField
                      required
                      type="text"
                      label="Group Description"
                      value={this.state.currentGroup.group_desc}
                      onChange={this.handleGroupDescChange}
                      inputProps={{ maxLength: 1000 }}
                      helperText="Maximum 1000 characters"
                      variant="outlined"
                      fullWidth
                      style={{ marginBottom: "20px" }}
                    />*/}
                    <div class="form-group">
                      <label class="form-label" for="group_desc1">
                        Description
                      </label>
                      <div class="form-control-wrap">
                        {this.state.isEditorReady ? (
                          <>
                            <RichTextEditorComponent
                              agreementContent={
                                this.state.currentGroup.group_desc
                              }
                              onContentChange={this.handleEditorChangeEdit}
                            />
                          </>
                        ) : null}
                        {/*<textarea
                          class="form-control"
                          id="group_desc1"
                          name="group_desc"
                          maxLength={1000}
                          onChange={this.handleGroupDescChange}
                          value={this.state.currentGroup.group_desc}
                        ></textarea>*/}
                      </div>
                    </div>
                    <Select2
                      isMulti
                      name="questions"
                      options={forms[0].questions
                        .filter((question) => {
                          const questionId = question.id;
                          return (
                            !this.state.groups
                              .flatMap((group) => group.questions)
                              .includes(questionId) ||
                            this.state.currentGroup.questions.includes(
                              questionId
                            )
                          );
                        })
                        .map((question) => ({
                          value: question.id,
                          label: question.questionText,
                        }))}
                      onChange={this.handleSelectChange}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      value={forms[0].questions
                        .filter((question) =>
                          this.state.currentGroup.questions.includes(
                            question.id
                          )
                        )
                        .map((question) => ({
                          value: question.id,
                          label: question.questionText,
                        }))}
                      placeholder="Select questions"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minHeight: "56px",
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          zIndex: 9,
                        }),
                      }}
                    />
                    <div class="d-flex justify-content-end">
                      <ul className="nk-block-tools g-3">
                        <li className="nk-block-tools-opt  mt-3">
                          <a
                            style={{ cursor: "pointer", color: "#fff" }}
                            onClick={() => this.closeModal("modalEditGroup")}
                            className={`btn btn-primary btn-lg`}
                          >
                            <em class="icon ni ni-shrink"></em>
                            <span>Cancel</span>
                          </a>
                        </li>
                        <li className="nk-block-tools-opt  mt-3">
                          <a
                            style={{ cursor: "pointer", color: "#fff" }}
                            onClick={() => this.handleEditGroupNew()}
                            className={`btn btn-primary  btn-lg`}
                          >
                            <em class="icon ni ni-edit"></em>
                            <span>Update Group</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*** ADD GROUP MODAL ***/}
        {/*** ADD QUESTION MODAL ***/}
        <div class="modal fade" tabindex="-1" id="modalAddQuestion">
          <div class="modal-dialog  modal-xl modal-dialog-top" role="document">
            <div class="modal-content">
              <a href="#" class="close" data-dismiss="modal" aria-label="Close">
                <em class="icon ni ni-cross"></em>
              </a>
              <div class="modal-header">
                <h5 class="modal-title">
                  <em class="icon ni ni-plus"></em>{" "}
                  <span>Add New Question</span>
                </h5>
              </div>
              <div class="modal-body">
                <div id="questionEditDiv">
                  <div
                    className="col-12"
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                  >
                    {this.state.questionErrorMessage !== "" ? (
                      <div className="example-alert mb-4">
                        <div className="alert alert-pro alert-danger">
                          <div className="alert-text">
                            <h4>{this.state.questionErrorMessageTitle}</h4>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: this.state.questionErrorMessage,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.questionSuccessMessage !== "" ? (
                      <div className="example-alert mb-4">
                        <div className="alert alert-pro alert-success">
                          <div className="alert-text">
                            <h4>Success</h4>
                            <p>{this.state.questionSuccessMessage}</p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {/*const newQuestion = [{
                        id: uuid(),
                        questionText: "",
                        answerType: "",
                        required: false,
                        options: [],
                        imageUrl: "",
                        sequenceNumber: "",
                      }];*/}

                    {this.state.newQuestion.map((question, indexes) => (
                      <div
                        key={`input_key_${question.id}`}
                        className={classes.questionContainer}
                      >
                        <div className={`row g-4 ${classes.questionTop}`}>
                          <div className="col-lg-2">
                            <MutextField
                              label="Seq #"
                              value={
                                question.sequenceNumber &&
                                question.sequenceNumber !== ""
                                  ? question.sequenceNumber
                                  : ""
                              }
                              onChange={(e) =>
                                this.handleSequenceChange_new(question.id, e)
                              }
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                          <div className="col-lg-10">
                            <div className="form-group">
                              <MutextField
                                label="Question Text"
                                value={question.questionText}
                                onChange={(e) =>
                                  this.handleQuestionTextChange_new(
                                    question.id,
                                    e
                                  )
                                }
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id={`answer-type-label-${question.id}`}
                                name={`answer-type-label-${question.id}`}
                                select
                                label="Answer Type"
                                value={question.answerType}
                                onChange={(e) =>
                                  this.handleAnswerTypeChange_new(
                                    question.id,
                                    e
                                  )
                                }
                                SelectProps={{
                                  native: true,
                                }}
                                variant="outlined"
                                fullWidth
                              >
                                <option key="" value=""></option>
                                <option key="input" value="input">
                                  Input
                                </option>
                                <option key="textarea" value="textarea">
                                  Textarea
                                </option>
                                <option key="dropdown" value="dropdown">
                                  Dropdown
                                </option>
                                <option key="input" value="label">
                                  Label
                                </option>
                                <option key="input" value="fixed">
                                  Fixed Fields
                                </option>
                                <option key="input" value="signature">
                                  Signature
                                </option>
                                <option key="checkbox" value="checkbox">
                                  Checkbox
                                </option>
                                <option key="radio" value="radio">
                                  Radio
                                </option>
                                <option key="image_input" value="image_input">
                                  Image Input
                                </option>
                                <option key="datetime" value="datetime">
                                  Datetime
                                </option>
                                <option key="date" value="date">
                                  Date
                                </option>
                                <option key="time" value="time">
                                  Time
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id={`dynamic-value-${question.id}`}
                                name={`dynamic-value-${question.id}`}
                                select
                                label="Dynamic Value"
                                value={question.dynamicValue}
                                onChange={(e) =>
                                  this.handleDynamicValue_new(question.id, e)
                                }
                                SelectProps={{
                                  native: true,
                                }}
                                variant="outlined"
                                fullWidth
                              >
                                <option key="" value=""></option>
                                <option key="input" value="fullname">
                                  Full Name
                                </option>
                                <option key="company2" value="company">
                                  Company
                                </option>
                                <option key="email2" value="email">
                                  Email Address
                                </option>
                                <option key="phone2" value="phone">
                                  Phone
                                </option>
                                <option
                                  key="full_address2"
                                  value="full_address"
                                >
                                  Full Address
                                </option>
                                <option key="address2" value="address">
                                  Address
                                </option>
                                <option key="appartment2" value="appartment">
                                  Appartment, Suite
                                </option>
                                <option key="city2" value="city">
                                  City
                                </option>
                                <option key="state2" value="state">
                                  State
                                </option>
                                <option key="zip2" value="zip">
                                  Zip
                                </option>
                                <option key="country2" value="country">
                                  Country
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id={`required-label-${question.id}`}
                                name={`required-label-${question.id}`}
                                select
                                label="Required"
                                value={question.required}
                                onChange={(e) =>
                                  this.handleRequiredChange_new(question.id, e)
                                }
                                SelectProps={{
                                  native: true,
                                }}
                                variant="outlined"
                                fullWidth
                              >
                                <option key="false" value="false">
                                  No
                                </option>
                                <option key="true" value="true">
                                  Yes
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id="selected_group"
                                name="selected_group"
                                select
                                label="Select Group"
                                value={this.state.selected_group}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                variant="outlined"
                                fullWidth
                              >
                                <option key="" value=""></option>
                                {this.state.groups &&
                                this.state.groups.length > 0 ? (
                                  <>
                                    {this.state.groups.map((group, index) => (
                                      <option
                                        key={`add_group_${index}`}
                                        value={group.id}
                                      >
                                        {group.group_title}
                                      </option>
                                    ))}
                                  </>
                                ) : null}
                              </MutextField>
                            </div>
                          </div>

                          {question.imageUrl && (
                            <div className="col-lg-12">
                              <img
                                src={question.imageUrl}
                                alt="Admin Uploaded"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "200px",
                                  display: "block",
                                }}
                              />
                            </div>
                          )}
                          {question.answerType === "checkbox" ||
                          question.answerType === "radio" ||
                          question.answerType === "dropdown" ? (
                            <div className="col-lg-12">
                              {question.options.map((option, index) => (
                                <div
                                  key={index}
                                  className={classes.optionContainer}
                                >
                                  <MutextField
                                    label={`Option ${index + 1}`}
                                    value={option}
                                    onChange={(e) =>
                                      this.handleOptionChange_new(
                                        question.id,
                                        index,
                                        e
                                      )
                                    }
                                    variant="outlined"
                                    fullWidth
                                  />
                                  <a
                                    className={`btn btn-dim btn-danger ${classes.deleteButton}`}
                                    onClick={() =>
                                      this.handleDeleteOption_new(
                                        question.id,
                                        index
                                      )
                                    }
                                  >
                                    Delete
                                  </a>
                                </div>
                              ))}
                              <a
                                className={`btn btn-outline-light`}
                                onClick={() =>
                                  this.handleAddOption_new(question.id)
                                }
                                style={{ marginTop: "20px" }}
                              >
                                Add Option
                              </a>
                            </div>
                          ) : null}
                          {question.answerType === "image_input" ? (
                            <div className="col-lg-12">
                              <input type="file" accept="image/*" disabled />
                            </div>
                          ) : null}
                          {question.answerType === "fixed" ? (
                            <div className="col-lg-12">
                              <div className="form-group">
                                <MutextField
                                  label="Value"
                                  value={question.answer}
                                  onChange={(e) =>
                                    this.handleFixedOption_new(question.id, e)
                                  }
                                  variant="outlined"
                                  fullWidth
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ))}

                    <div class="d-flex justify-content-end">
                      <ul className="nk-block-tools g-3">
                        {this.state.add_question_loader === true ? (
                          <li className="nk-block-tools-opt  mt-3">
                            <button className="btn btn-lg btn-primary" disabled>
                              <em className="icon ni ni-swap"></em> Saving
                              <div
                                className="spinner-grow spinner-grow-sm"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </button>
                          </li>
                        ) : (
                          <>
                            <li className="nk-block-tools-opt  mt-3">
                              <a
                                style={{ cursor: "pointer", color: "#fff" }}
                                onClick={() =>
                                  this.closeModal("modalAddQuestion")
                                }
                                className={`btn btn-primary btn-lg`}
                              >
                                <em class="icon ni ni-shrink"></em>
                                <span>Cancel</span>
                              </a>
                            </li>
                            <li className="nk-block-tools-opt  mt-3">
                              <a
                                style={{ cursor: "pointer", color: "#fff" }}
                                onClick={() => this.submitNewQuestion()}
                                className={`btn btn-primary  btn-lg`}
                              >
                                <em class="icon ni ni-plus"></em>
                                <span>Add Question</span>
                              </a>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*** ADD QUESTION MODAL ***/}
      </div>
    );
  }
}

export default withStyles(useStyles)(FormBuilderClass);
